<template>
    <div>
        <div>
            <breadcrumbs :data="menus"/>
        </div>
        <div class="filter">
            <form class="pure-form pure-form-stacked">
                <fieldset>
                    <legend></legend>
                    <div class="pure-g" style="justify-content: flex-end;">
                        <div class="pure-u-1-4">
                            <label for="multi-state"></label>
                            <select id="multi-state" style="width: 100%;" v-model="filterForm.tz">
                                <option v-for="(val, idx) in options.tzs" :key="idx" :value="tzi(val.offset)">
                                    {{ val.label }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <div style="padding: 0.3em;">
                                <button type="submit" class="pure-button pure-button-primary"
                                        style="margin-right: 0.4em;" @click="refreshData">Query
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="data-area">
            <h4>OneTimePayment Summary</h4>
            <div class="pure-g">
                <div class="pure-u-1-3 card-wrapper">
                    <div class="card">
                        <div class="pure-g">
                            <div class="pure-u-1-2">Today</div>
                            <div class="pure-u-1-2" style="text-align: right;">
                                <router-link to="/cpdash/summary/otp">More</router-link>
                            </div>
                        </div>
                        <div style="font-size:36px; font-weight: bold;">{{ data.otps.otp1 }}</div>
                    </div>
                </div>
                <div class="pure-u-1-3 card-wrapper">
                    <div class="card">
                        <div class="pure-g">
                            <div class="pure-u-1-2">Yesterday</div>
                            <div class="pure-u-1-2" style="text-align: right;">
                                <router-link to="/cpdash/summary/otp">More</router-link>
                            </div>
                        </div>
                        <div style="font-size:36px; font-weight: bold;">{{ data.otps.otp2 }}</div>
                    </div>
                </div>
                <div class="pure-u-1-3 card-wrapper">
                    <div class="card">
                        <div class="pure-g">
                            <div class="pure-u-1-2">ThisMonth</div>
                            <div class="pure-u-1-2" style="text-align: right;">
                                <router-link to="/cpdash/summary/otp">More</router-link>
                            </div>
                        </div>
                        <div style="font-size:36px; font-weight: bold;">{{ data.otps.otp3 }}</div>
                    </div>
                </div>
            </div>
            <h4>Subscription Summary</h4>
            <div class="pure-g">
                <div class="pure-u-1-3 card-wrapper">
                    <div class="card">
                        <div class="pure-g">
                            <div class="pure-u-1-2">Today</div>
                            <div class="pure-u-1-2" style="text-align: right;">
                                <router-link to="/cpdash/summary/sub">More</router-link>
                            </div>
                        </div>
                        <div style="font-size:36px; font-weight: bold;">{{ data.subs.sub1 }}</div>
                    </div>
                </div>
                <div class="pure-u-1-3 card-wrapper">
                    <div class="card">
                        <div class="pure-g">
                            <div class="pure-u-1-2">Yesterday</div>
                            <div class="pure-u-1-2" style="text-align: right;">
                                <router-link to="/cpdash/summary/sub">More</router-link>
                            </div>
                        </div>
                        <div style="font-size:36px; font-weight: bold;">{{ data.subs.sub2 }}</div>
                    </div>
                </div>
                <div class="pure-u-1-3 card-wrapper">
                    <div class="card">
                        <div class="pure-g">
                            <div class="pure-u-1-2">ThisMonth</div>
                            <div class="pure-u-1-2" style="text-align: right;">
                                <router-link to="/cpdash/summary/sub">More</router-link>
                            </div>
                        </div>
                        <div style="font-size:36px; font-weight: bold;">{{ data.subs.sub3 }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import {BrowserDefaultTimezone, MinimalTimezoneSet, TimezoneNumberOffset} from "@/utils/tz";
import {CpDashSummaryDash} from "@/store/api/summary";

export default {
    name: "Dash",
    components: {Breadcrumbs},
    data() {
        return {
            menus: [{label: 'Home', to: '/cpdash/dash'}, {label: 'Dash', to: ''}],
            options: {tzs: MinimalTimezoneSet,},
            data: {otps: {otp1: 0, otp2: 0, otp3: 0}, subs: {sub1: 0, sub2: 0, sub3: 0}},
            filterForm: {
                tz: BrowserDefaultTimezone(),
            },
        }
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        tzi(val) {
            return TimezoneNumberOffset(val)
        },
        refreshData() {
            CpDashSummaryDash(this.filterForm).then((resp) => {
                this.data = resp.data.data.data;
                this.ts = new Date(resp.data.data.ts * 1000 + this.filterForm.tz * 3600 * 1000).toISOString();
            }).finally(() => {

            });
        }
    }
}
</script>

<style scoped lang="scss">
.filter {
    margin-top: 1em;
}

.table-main {
    margin: 1em 0;
}

.pure-form input[type=date] {
    padding: 0.34em 0.6em;
}

.card-wrapper {
    box-sizing: border-box;
    padding: 0 0.5em;
}

.card {
    box-sizing: border-box;
    padding: 1em;
    border: 1px solid #eee;
}
</style>